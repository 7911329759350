"use strict";

const validation = input => {
  return {
    init(input) {
      this.input = input;

      this.parent = input.closest(".field");
      this.listeners();
    },

    validationFunction() {
      const value = this.input.value;
      const type = this.input.type;
      const required = this.input.required;

      if (required && value === "" && !required) return this.validated(this.parent, "Warning", "svg--warning");

      switch (type) {
        case "text": {
          const data = this.validateString(value);
          if ((data == "" || data == false) && required) return this.validated(this.parent, "No valid field", "svg--error");
          break;
        }

        case "tel": {
          const data = this.validatePhoneNumber(value);
          if (data == false) return this.validated(this.parent, "No valid phone", "svg--error");
          break;
        }

        case "email": {
          const data = this.validateEmail(value);
          if (data == null) return this.validated(this.parent, "No valid email", "svg--error");
          break;
        }
      }

      if (required && type !== 'checkbox') this.validated(this.parent, "Success", "svg--success");
    },

    validateString(stringName) {
      this.input.pattern = "[a-zA-Zа-яА-Я]*";
      const newstr = stringName.replace(/[^a-zA-Zа-яА-Я]/g, "");
      return newstr.length !== 0 && this.input.validity.valid;
    },

    validatePhoneNumber(input_str) {
      this.input.pattern = "[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{2}[-s.]?[0-9]{4,6}";
      let re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{2}[-\s\.]?[0-9]{4,6}$/im;
      return re.test(input_str) && this.input.validity.valid;
    },

    validateEmail(email) {
      this.input.pattern = "[a-zA-Zа-яА-Я0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Zа-яА-Я0-9-]+(?:.[a-zA-Zа-яА-Я0-9-]+)*";
      const check = email.toLowerCase().match(/^[a-zA-Zа-яА-Я0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Zа-яА-Я0-9-]+(?:\.[a-zA-Zа-яА-Я0-9-]+)*$/);
      return check && this.input.validity.valid;
    },

    validated(parent, text, className) {
      // if message div exist, remove it
      let messageDiv = parent.querySelector(".message");
      if (messageDiv) messageDiv.remove();

      // we must create new message div and append it
      const message = document.createElement("p");
      [className, "message"].forEach(className => {
        message.classList.add(className);
      });
      message.innerText = text;
      parent.append(message);

      // item.addEventListener('input', () => {
      //     if (message) message.remove();
      // }, {once: true});
    },

    listeners() {
      this.input.addEventListener("input", this.validationFunction.bind(this));
      this.input.addEventListener("focus", this.validationFunction.bind(this));
    },
  }.init(input);
};
