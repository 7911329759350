"use strict";

const form = {
  init(form) {
    this.form = form;
    this.inputs = this.form.querySelectorAll("input");
    this.responseText = this.form.querySelector(".modal-response-text");
    this.sendAgain = this.form.querySelector(".send-again");
    this.sender = this.form.querySelector(".sender");
    this.loadingEls = {
      loader: this.sender.querySelector(".loader"),
      text: this.sender.querySelector("span"),
    };
    if (!this.inputs.length) return;

    this.close = this.form.querySelectorAll(".close-modal");
    this.inputs.forEach(input => {
      if (input.getAttribute("data-novalid") === null) {
        validation(input);
      }
    });

    this.listeners();
  },

  listeners() {
    if (this.close.length) {
      this.close.forEach(btn => {
        btn.addEventListener("click", () => {
          this.form.classList.remove("active");
          this.sendAgain.click();
        });
      });
    }

    if (this.sendAgain) {
      this.sendAgain.addEventListener("click", () => {
        this.inputs.forEach(input => {
          if (input.type === "checkbox") {
            input.checked = false;
          } else {
            input.value = "";
          }

          const field = input.closest(".field");
          if (field) {
            const message = field.querySelector(".message");
            if (message) message.remove();
          }
        });
        this.sendAgain.parentNode.classList.add("hidden-true");
      });
    }

    this.form.addEventListener("submit", e => {
      e.preventDefault();
      const { loader, text } = this.loadingEls;
      loader.classList.remove("hidden-true");
      text.classList.add("hidden-true");

      const formData = new FormData(this.form);

      fetch("php/sendmain.php", {
        method: "POST",
        body: formData,
      })
        .then(res => res.text())
        .then(text => {
          this.responseText.parentNode.classList.remove("hidden-true");
          this.responseText.innerHTML = text;
        })
        .finally(() => {
          loader.classList.add("hidden-true");
          text.classList.remove("hidden-true");
        });
    });
  },
};

const forms = document.querySelectorAll("form");
if (forms) {
  forms.forEach(formEl => form.init(formEl));
}
